// https://single-spa.js.org/docs/layout-overview/
import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

const routes = constructRoutes(document.querySelector("#single-spa-layout"));
function placeLoader() {
  document.querySelector("#single-spa-main").appendChild(
    Object.assign(document.createElement("div"), {
      id: "single-spa-loader",
      textContent: "loading...",
      style: "margin: 20px; text-align: center; font-size: 50px; ",
    })
  );
}

function removeLoader() {
  document.getElementById("single-spa-loader").remove();
}
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    // return System.import(name);
    // add custom loading
    return Promise.resolve()
      .then(() => {
        placeLoader();
        return System.import(name);
      })
      .then((app) => {
        removeLoader();
        return app;
      });
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

System.import("@cc/root-config").then(() => {
  layoutEngine.activate();
  start();
});
